.slick-list {
  /* margin: 0; */
}
.slick-slide > div {
  padding: 3rem 1.7rem;
}

@media (max-width:340px) {
  .slick-slide > div {
    padding: 2rem 0.4rem;
  }
}
