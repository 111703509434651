* {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");



.h1 {
  color: #262626;

  text-align: center;
  font-family: "Nunito";
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media only screen and (min-width: 380px) and (max-width: 500px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 0px) and (max-width: 379px) {
    font-size: 24px;
  }
}

.h2 {
  color: #262626;
  text-align: center;
  font-family: Calibri;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  @media only screen and (min-width: 380px) and (max-width: 500px) {
    font-size: 21px;
  }

  @media only screen and (min-width: 0px) and (max-width: 379px) {
    font-size: 17px;
  }
}

.h3 {
  font-family: "Nunito", sans-serif;
  background-color: #fff;
  border-radius: 100%;
  color: #262626;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #262626;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
}

.h4 {
  color: #262626;

  text-align: center;
  font-family: "Nunito";
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.h5 {
  color: #262626;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
}

.body1 {
  color: #3a3939;
  margin-top: -2px;

  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.body2 {
  color: #3a3939;
  font-family: "Calibri";
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 2px;
}

.cards {
  justify-content: center;
}

.card:hover {
  transition: 0.3s ease 2s;
  min-height: 540px;
  background-color: #fff;
  transition-delay: 0.1s;

  .h3 {
    background-color: #00c6ff;
  }

  .features {
    background: #00c6ff;
  }
}

.card {
  border: 1px solid lightyellow;
  border-radius: 12px;
  position: relative;
  min-width: 220px;
  min-height: 540px;
  height: 100%;
  background-color: #fff087;

  @media only screen and (min-width: 900px) and (max-width: 1235px) {
    min-width: 280px;
  }
}

.cardContent {
  margin-top: -6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px;
  gap: 12px;
}

.features {
  background: rgba(255, 255, 255, 0.15);
}

.btndiv {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefits {
  display: flex;
  align-items: center;
}

.h6 {
  color: #fff;
  text-transform: capitalize;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.benefits {
  margin: 0;
}
