:root {
  --base-font-size: 10px;
  /* Define a base font size */
}

.msgContainer {
  /* background-color: pink; */
  height: 110px;
  width: 250px;
  position: relative;
}

.msgImage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: -18px;
  right: -75px;
}

.msgUp {
  color: #000;
  font-size: 15px;
  position: absolute;
  top: 8px;
  right: -45px;
}

.msgDown {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 30px;
  right: -38px;
}

.teddiImgContainer {
  height: 100%;
  width: 80%;
}

.teddiImg {
  height: 80%;
  max-width: 100%;
}

.buyCard {

  text-align: center;
  justify-content: center;  /* Center content horizontally */
  align-items: center;     /* Center content vertically */
           /* Adjust as needed */
}

.buyBox {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;   
  text-align: center;   
}

.imageContainer {
  display: flex;
  justify-content: center; 
  align-items: center;   
}

.teddiBuyImg {
  height: auto;           
  max-width: 50%;          
  object-fit: cover;       
  margin: 0;              
  padding: 0;            
  border: none;           
  display: block;         
  overflow: hidden;       
}


.buyName{
  font-size: 16px;
  font-weight: 700;
  color: rgb(78, 77, 77);
}
.buyAmount{
  font-size: 28px;
  font-weight: 700;
}

.buyButton{
  height: 10%;
text-transform: none;
}

.startTextImg {
  width: 70%;
  height: 60px;
  background: url("/public/StartText.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0 0 0.25rem #000048);
}

.imageContainer {
  /* background-color: pink; */
  width: 285px;
  height: 180px;
  position: relative;
}

.image {
  height: 100%;
  width: 100%;
}

.textContainer {
  /* background-color: aqua; */
  height: 70%;
  width: 75%;
  position: absolute;
  top: 27px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textContainer2 {
  /* background-color: aqua; */
  height: 70%;
  width: 75%;
  position: absolute;
  top: 27px;
  left: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-size: 15px;
  font-weight: 800;
  font-family: Nunito, sans-serif;
  color: #000;
  padding: 10px 0;
}

.text {
  font-size: 12px;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  color: #000;
}

.phoneContainer {
  width: 100%;
  overflow: hidden;
}

.phoneImage {
  width: 100%;
  height: 100%;
  right: -5px;
  top: -55px;
  z-index: 10;
  position: relative;
}

.shape {
  position: relative;
}

.shape::after {
  position: absolute;
  top: 100%;
  left: 0;
  content: "";
  background: url("/public/bg2.png") no-repeat;
  width: 100%;
  height: 250px;
  background-size: 100%;
  z-index: 0;
}

.carouselContainer {
  width: 83%;
  margin: 0 auto;
}

@media (max-width: 1440px) {
  .msgImage {
    height: 115%;
    width: 100%;
    padding: 0 10px 0 0;
  }

  .msgUp {
    top: 12px;
    left: 120px;
    font-size: 13px;
  }

  .msgDown {
    top: 35px;
    font-size: 11px;
    right: -20px
  }
}

@media (max-width: 1024px) {
  .msgImage {
    height: 100%;
    width: 115%;
    right: -60px;
  }

  .msgUp {
    top: 12px;
    left: 50px;
    font-size: 12px;
  }

  .msgDown {
    font-size: 13px;
    right: -23px;
  }

  .phoneContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phoneImage {
    width: 868px;
    height: 645px;
    right: -20px;
    top: 0;
  }
}
@media (max-width: 770px) {


  .msgUp {
    top: 12px;
    left: 80px;
    font-size: 12px;
  }
}
@media (max-width: 660px) {


  .msgUp {
    top: 12px;
    right: 10px;
    font-size: 11px;
  }
}
@media (max-width: 400px) {
 

  .msgUp {

    font-size: 10px;
    
  }
}
@media (max-width: 370px) {
 

  .msgUp {
    left: 50px;
    font-size: 10px;
    
  }
}
@media (min-width: 1024px) and (max-width: 1416px) {
  .phoneImage {
    width: 990px;
  }
}

@media (max-width: 768px) {
  .teddiImg {
    width: 45vw;
  }

  .msgImage {
    right: -78px;
  }

  .phoneImage {
    right: -30px;
  }
}

@media (max-width: 650px) {
  .msgImage {
    right: -54px;
  }
}

@media (max-width: 600px) {
  .phoneImage {
    width: 450px;
    height: 460px;
    right: -40px;
    top: 0;
  }
}

@media (max-width: 550px) {
  .phoneImage {
    width: 398px;
    right: -30px;
  }
}

/* @media (max-width: 425px) {
  .msgContainer {
    width: 153px;
  }

  .msgImage {
    width: 150%;
    right: -52px;
  }
} */

@media (max-width: 375px) {
  .phoneImage {
    width: 375px;
    right: -10px;
  }
}

@media (max-width: 340px) {
  .phoneImage {
    width: 318px;
  }

  .msgDown {
    font-size: 11px;
  }
}

@media (max-width: 1340px) {
  .imageContainer {
    width: 220px;
    height: 140px;
  }

  .textContainer {
    height: 60%;
  }

  .textContainer2 {
    height: 60%;
  }

  .heading {
    font-size: 12px;
    padding: 5px 0;
  }

  .text {
    font-size: 10px;
  }
}

.back {
  /* background-color: #00c6ff;
  height: 100%;
  width: 100%;
  border-radius: 33% 67% 91% 9% / 92% 17% 83% 8%; */
  position: absolute;
  z-index: 1;

}

.up {
  /* background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 77% 23% 84% 16% / 18% 89% 11% 82%; */
  position: absolute;
  z-index: 2;

}

.profile {
  position: absolute;
  z-index: 11;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 85px;
  top: -40px;
  border-radius: 100%;
}

.profile_container {
  width: 100%;
  /* background: pink; */
  display: flex;
  justify-content: center;
}

.text_section {
  position: absolute;
  z-index: 110;
  display: flex;
  width: 100%;
  top: 70px;
  align-items: center;
  flex-direction: column;
}

.headingT {
  font-weight: bold;
  font-size: 20px;
  font-family: Nunito;
  color: '#262626';
  font-weight: 500;
}

.sub_heading {
  font-size: 16px;
  font-weight: 400;
  color: #8d8d8d;
  font-family: Nunito;
}

.inverted {
  margin: 10px 0;
}

.textT {
  width: 68%;
  font-size: 14px;
  color: #636363;
  max-height: 114px;
  font-family: Nunito;
  font-weight: 400;
  overflow-wrap: break-word;
  
  overflow: hidden;
}
@media (max-width: 1020px) {
  .textT {
    width: 38%;
    font-size: 14px;
    color: #636363;
    max-height: 114px;
    font-family: Nunito;
    font-weight: 400;
    overflow-wrap: break-word;
    
    overflow: hidden;
  }

}