* {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

@media (min-width: 600px) and (max-width: 1024px) {
  .box {
    padding: 40px;
  }
  .btnDecription {
    font-size: 12px;
  }
}

@media (max-width: 599px) {
  .box {
    width: 330px !important;
    padding: 12px !important;
  }
  .btnDecription {
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .box {
    width: 310px !important;
    
  }
}
@media (max-width: 340px) {
  .box {
    width: 262px !important;
    
  }
}


.box {
  /* border:1px solid black; */
  box-shadow: rgba(0, 0, 0, 0.26) 0px 3px 8px;
  width: 400px;
  /* background-color:rgba(236, 227, 227, 0.56) ; */
  max-width: 570px;
  /* height: "auto"; */
  /* max-height: 515px; */
  /* padding: 10px 20px 30px 20px; */
  padding: 2.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}



.heading {
  color: rgba(0, 0, 0, 0.87);
  /* margin-top: -10px; */
  margin-bottom: 15px;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* text-transform: uppercase; */
}

.childSection{
  /* margin-bottom: 10px; */
  margin-top: 10px;
  position: relative;
  padding: 2px;
}

.iconBtn {
  position: absolute;
  left: 51px;
  padding: 12px 12px 7px !important;
}

.content {
  align-items: center;
  margin-top: -8px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 16px;
  /* width: 415px; */
}

.btnDecription {
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: black;
}

.check {
  display: flex;
  gap: 2px;
  align-items: baseline;
  margin-top: 10px;
  line-height: 16.37px  ;
  font-family: Nunito;
font-size: 12px;
font-weight: 400;
text-align: left;

}
.tnc {
  color: #262626;
  align-items: start important!;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.snc {
  color: #262626;
  /* align-items: start important!; */
  margin-left: 6px;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}