@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin: 15px !important;
  }
}

.slick-slide {
  transition: transform 0.5s ease;
}

.slick-center .testimonial {
  transform: scale(1.1);
}

.slick-slide:not(.slick-center) .testimonial {
  transform: scale(0.8);
}

.testimonial {
  display: flex !important;
  justify-content: center !important;
}

.css-k4qjio-MuiFormHelperText-root {}

.css-1t6c9ts {
  padding-left: 18px !important;

}

.css-1wc848c-MuiFormHelperText-root {
  display: contents !important;
}

.css-qxum78-MuiTypography-root {
  -webkit-text-stroke: medium !important;
}