.imgContainer{
  height: 400px;
  width: 380px;
}

.mainImg {
  background: url(/public/teddi.png);
  height: 100%;
  /* width: 380px; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.uptoImg {
  background: url(/public/upto.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  width: 100px;
  position: absolute;
  left: 268px;
  top: 120px;
}

@media (max-width:500px){
  .imgContainer{
    height: 300px;
    width: 280px;
  }
  .uptoImg{
    height: 75px;
    width: 75px;
    left: 200px;
  }
}