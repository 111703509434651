.imgContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.image {
  background: url("/public/contact-png-image.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  /* padding-top: 3rem; */
  height: 100%;
  width: 90%;
}

@media (max-width: 768px) {
  .imgContainer {
    height: 350px;
    width: 300px;
    
  }
  .image {
    padding-top: 0;
    height: 100%;
    width: 90%;
  }
}
